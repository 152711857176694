
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  reactive,
  nextTick,
} from "vue";
import Header from "@/components/header/index.vue";
import Tail from "@/components/tail/index.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Home",
  components: { Header, Tail },
});
